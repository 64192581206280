<script>
/* eslint-disable vue/no-v-html */
</script>
<template>
  <div>
    <ejs-toast
      id="toast_type"
      ref="toastRef"
      :position="{ X: 'Right' }"
    />
    <b-modal
      id="modal-statoservizio-event-handler"
      ref="modal-statoservizio-event-handler"
      v-model="show"
      ok-only
      ok-title="Crea"
      centered
      size="md"
      :title="titleEventHandler"
      @ok.prevent="handleOk"
    >
      <!-- {{ statoServizio }} -->
      <validation-observer ref="validation-observer-statoServizio-modal-event-handler">
        <b-row>
          <b-col cols="4">
            <validation-provider
              #default="{ errors }"
              name="codice"
              rules="required"
            >
              <b-form-group
                label="Codice"
                label-class="font-weight-bolder"
              >
                <b-input-group>
                  <b-form-input
                    v-model="statoServizio.codice"
                    placeholder="codice"
                  />
                </b-input-group>
                <small class="text-danger mt-25">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="8">
            <validation-provider
              #default="{ errors }"
              name="descrizione"
              rules="required"
            >
              <b-form-group
                label="Descrizione"
                label-class="font-weight-bolder"
              >
                <b-input-group>
                  <b-form-input
                    v-model="statoServizio.descrizione"
                    placeholder="descrizione"
                  />
                </b-input-group>
                <small class="text-danger mt-25">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormGroup, BInputGroup, BFormInput, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import { setStatoServizio } from '@/@core/api-service/shared/combo'

export default {
  components: {
    BRow, BCol, BFormGroup, BInputGroup, BFormInput, ValidationProvider, ValidationObserver,
  },
  data() {
    return {
      show: false,
      titleEventHandler: '',
      statoServizio: {
        id: 0,
        codice: '',
        descrizione: '',
      },
      required,
      locale: 'it',

    }
  },
  mounted() {
    localize(this.locale)
  },
  methods: {
    handleOk() {
      this.setUnita()
    },
    setUnitaUtilizzoModal(statoServizio) {
      this.statoServizio = { ...statoServizio }
    },
    openModal() {
      this.show = true
    },
    setTitle(title) {
      this.titleEventHandler = title
    },
    async setUnita() {
      setStatoServizio([{...this.statoServizio}])
        .then(res => {
        // console.log(res)
        if (res.data.esito === 'OK') {
          // console.log('ok')
          // console.log('reparti', res.data.statoServizio)
          // dipatch response
          // console.log('this.statoServizio', this.statoServizio)
          const response = this.statoServizio.id === 0 ? { ...res.data.statiServizioAggiunte[0] } : { ...res.data.statiServizioAggiornate[0] }
          // console.log('responseee', response)
          this.$store.dispatch('dizionari/setStatoServizioById', response)
          // this.$emit('update-unita', unita)
          const typeOperation = this.statoServizio.id === 0 ? 'Aggiunto' : 'Modificato'
          this.resetUnitaUtilizzo()
          this.$refs.toastRef.show({
            title: 'Operazione Completata!', content: `'Stato Servizio/i ${typeOperation} Correttamente'`, cssClass: 'e-toast-success', icon: 'e-success toast-icons',
          })
          this.$nextTick(() => {
            this.$refs['modal-statoservizio-event-handler'].toggle('#toggle-btn')
          })
        }
      })
        .catch(e => e)
      // console.log('this.categories', this.categories)
    },
    resetUnitaUtilizzo() {
      this.statoServizio = {
        id: 0,
        codice: '',
        descrizione: '',
      }
    },
  },
}
</script>

<style>

</style>

<template>
  <b-tabs
    pills
  >
    <b-tab
      active
      title="Unità di Misura"
    >
        <unita-misura-tabs />
    </b-tab>
    <b-tab
      title="Unità Utilizzo"
    >
      <unita-utilizzo-tabs />
    </b-tab>
    <b-tab
      title="Stato Servizio"
    >
      <stato-servizio-tabs />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import UnitaMisuraTabs from './tabs/UnitaMisuraTabs.vue'
import UnitaUtilizzoTabs from './tabs/UnitaUtilizzoTabs.vue'
import StatoServizioTabs from './tabs/StatoServizioTabs.vue'
import { getCombo } from '@/@core/api-service/shared/combo'

export default {
  components: {
    BTabs,
    BTab,
    UnitaMisuraTabs,
    UnitaUtilizzoTabs,
    StatoServizioTabs,
  },
  mounted() {
    getCombo()
      .then(res => {
        // console.log('getCombo', res)
        if (res.data.esito === 'OK') {
          // this.unitaMisura = [...res.data.unitaMisura]
          this.$store.dispatch('dizionari/setUnitaMisura', res.data.unitaMisura)
          this.$store.dispatch('dizionari/setUnitaUtilizzo', res.data.unitaUtilizzo)
          this.$store.dispatch('dizionari/setStatoServizio', res.data.statoServizio)
          // this.$store.dispatch('catalogs/setCatalogs', res.data.cataloghi)
        }
      })
      .catch(e => e)
  },
}
</script>

export const alertProduct = {
  methods: {
    productDeletedCorrectlyAlert() {
      this.$bvToast.toast('Prodotto/i Eliminato/i Correttamente', {
        title: 'Operazione Completata!',
        toaster: 'b-toaster-top-right',
        variant: 'success',
        icon: 'CheckIcon',
        solid: true,
      })
    },
    productAddedCorrectlyAlert() {
      this.$bvToast.toast('Prodotto/i Inserito/i Correttamente', {
        title: 'Operazione Completata!',
        toaster: 'b-toaster-top-right',
        variant: 'success',
        icon: 'CheckIcon',
        solid: true,
      })
    },
    productAddedCheckDataAlert() {
      this.$bvToast.toast('Controlla i dati inseriti', {
        title: 'Errore Inserimento',
        toaster: 'b-toaster-top-right',
        variant: 'danger',
        icon: 'XCircleIcon',
        solid: true,
      })
    },
    productEditCorrectlyAlert() {
      this.$bvToast.toast('Prodotto Modificato Correttamente', {
        title: 'Operazione Completata!',
        variant: 'success',
        icon: 'CheckIcon',
        solid: true,
      })
    },
  },
}
export const _ = null

/* eslint-disable  */
import axios from 'axios'

export function getCombo() {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER, {},
        {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
                classe: 'Diz-Dizionari',
                metodo: 'getDizionariCombo',
                ACL: '',
            },
        })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}
export function setUnitaMisura(unita) {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER, unita,
        {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
                classe: 'Diz-UnitaMisura',
                metodo: 'setUnitaMisura',
                ACL: '',
            },
        })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}
export function deleteUnitaMisura(unita) {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER, unita,
        {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
                classe: 'Diz-UnitaMisura',
                metodo: 'deleteUnitaMisura',
                ACL: '',
            },
        })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}
export function setUnitaUtilizzo(unita) {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER, unita,
        {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
                classe: 'Diz-UnitaUtilizzo',
                metodo: 'setUnitaUtilizzo',
                ACL: '',
            },
        })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}
export function deleteUnitaUtilizzo(unita) {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER, unita,
        {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
                classe: 'Diz-UnitaUtilizzo',
                metodo: 'deleteUnitaUtilizzo',
                ACL: '',
            },
        })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}

export function setStatoServizio(unita) {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER, unita,
        {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
                classe: 'Diz-StatoServizio',
                metodo: 'setStatiServizio',
                ACL: '',
            },
        })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}

export function deleteStatoServizio(unita) {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER, unita,
        {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
                classe: 'Diz-StatoServizio',
                metodo: 'deleteStatiServizio',
                ACL: '',
            },
        })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}
<script>
/* eslint-disable vue/no-v-html */
</script>
<template>
  <b-card>
    <ejs-toast
      id="toast_type"
      ref="toastRef"
      :position="{ X: 'Right' }"
    />
    <div class="col-lg-12 control-section p-0">
      <div class="content-wrapper">
        <!-- <div>
          <ejs-dropdownlist
            id="dropdownlist"
            ref="dropdown"
            :data-source="ddData"
            width="220px"
            :fields="fields"
            :change="valueChange"
            :value="ddValue"
          />
          <span
            id="msg"
            ref="msgelement"
            v-html="loadTime"
          />
          <br>
        </div> -->
        <ejs-grid
          id="overviewgridUnitaUtilizzo"
          ref="overviewgridUnitaUtilizzo"
          :data-source="getUnitaMisura"
          :selection-settings="selectionSettings"
          :edit-settings="editSettings"
          :allow-filtering="true"
          :filter-settings="filterOptions"
          :show-column-chooser="true"
          :allow-selection="true"
          :allow-sorting="true"
          :allow-resizing="true"
          :enable-virtualization="false"
          :allow-row-drag-and-drop="false"
          height="600"
          :allow-excel-export="true"
          :allow-pdf-export="true"
          row-height="38"
          :enable-hover="false"
          :enable-header-focus="true"
          :load="load"
          :toolbar="toolbar"
          :toolbar-click="toolbarClick"
          :action-begin="actionTableBegin"
          :action-complete="actionTableComplete"
          :allow-grouping="false"
          :allow-paging="true"
          :page-settings="pageSettings"
          :context-menu-items="contextMenuItems"
          :context-menu-click="contextMenuClick"
          :data-bound="dataBound"
          :row-data-bound="rowDataBound"
          :command-click="commandColumm"
        >
          <e-columns>
            <e-column
              type="checkbox"
              :allow-filtering="false"
              :allow-sorting="false"
              width="60"
            />
            <e-column
              field="id"
              :visible="false"
              header-text="id"
              :is-primary-key="true"
              width="130"
            />
            <e-column
              field="codice"
              :visible="true"
              header-text="codice"
              width="130"
            />
            <e-column
              field="descrizione"
              header-text="Descrizione"
              width="170"
              clip-mode="EllipsisWithTooltip"
            />
            <e-column
              field="Azioni"
              :lock-column="true"
              header-text="Azioni"
              width="240"
              :allow-editing="false"
              :commands="commands"
              :allow-sorting="false"
            />
          </e-columns>
        </ejs-grid>
      </div>
    </div>
    <!-- <SettingsPdfExport
      :show="showModalSettingPdfExport"
      @pdf-export-confirm-close="closeModal"
    /> -->
    <modal-unita-utilizzo-event-handler
      ref="modal-unitautilizzo-event-handler"
    />
  </b-card>
</template>

<script>
/* eslint-disable no-plusplus */
/* eslint-disable no-multi-str */
/* eslint-disable vue/no-side-effects-in-computed-properties */

import Vue from 'vue'
import { ToastPlugin } from '@syncfusion/ej2-vue-notifications'
import {
  GridPlugin, Sort, Filter, Selection, PdfExport, ExcelExport, Toolbar, Edit, Page, ColumnChooser, ContextMenu, CommandColumn, Resize, Scroll, Aggregate,
} from '@syncfusion/ej2-vue-grids'
// import { DropDownListPlugin } from '@syncfusion/ej2-vue-dropdowns'
import Ripple from 'vue-ripple-directive'
import { BCard } from 'bootstrap-vue'
import { L10n, setCulture, Browser } from '@syncfusion/ej2-base'
import moment from 'moment'
import axios from 'axios'
import { alertProduct } from '@core/mixins/ui/alert'
import { italian } from '@/@core/grid-translation/it-IT'
import ModalUnitaUtilizzoEventHandler from '@core/components/dizionari/ModalUnitaUtilizzoEventHandler.vue'
import { deleteUnitaUtilizzo } from '@/@core/api-service/shared/combo'

Vue.use(GridPlugin)
Vue.use(ToastPlugin)
//Vue.use(DropDownListPlugin)
setCulture('it-IT')
L10n.load(italian)

export default {
  directives: {
    Ripple,
  },
  components: {
    // BContainer,
    BCard,
    ModalUnitaUtilizzoEventHandler,
  },
  mixins: [alertProduct],
  data() {
    return {
      rowModeMobile: 'Vertical',
      rowMode: 'Horizontal',
      wrapSettings: { wrapMode: 'Content' },
      // codeColumnSearch,
      isDesktop: !Browser.isDevice,
      unitaMisura: [],
      filterSettingsMobile: {
        type: 'Excel',
      },
      filterOptions: {
        type: 'Menu',
      },
      filter: {
        type: 'CheckBox',
      },
      selectionSettings: {
        persistSelection: false, type: 'Multiple', checkboxOnly: true,
      },
      commands: [
        { type: 'Elimina', id: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } },
        { type: 'Save', buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' } },
        { type: 'Cancel', buttonOption: { iconCss: 'e-icons e-cancel-icon', cssClass: 'e-flat' } },
        {
          type: 'Dettagli',
          id: 'Dettagli',
          target: '.e-column',
          buttonOption: {
            iconCss: 'e-icons e-settings',
            cssClass: 'e-flat',
          },
        }],
      contextMenuItems: ['AutoFit', 'AutoFitAll', 'SortAscending', 'SortDescending',
        'Copy', 'Save', 'Cancel',
        'PdfExport', 'ExcelExport', 'CsvExport', 'FirstPage', 'PrevPage',
        'LastPage', 'NextPage', 'Group', 'Ungroup',
        {
          text: 'Elimina', tooltipText: 'Delete', iconCss: 'e-icons e-delete', id: 'DeleteCustom',
        },
        {
          text: 'Dettagli',
          target: '.e-content',
          id: 'Dettagli',
          iconCss: 'e-icons e-folder-open',
        },
      ],
      pageSettings: { pageSize: 25, pageSizes: [25, 50, 100, 200], pageCount: 5 },
      showModalSettingPdfExport: false,
      editSettings: {
        showDeleteConfirmDialog: false,
        allowEditing: false,
        allowAdding: true,
        allowDeleting: true,
        mode: 'Normal',
      },
      toolbarMobile: [{
        text: 'Elimina', tooltipText: 'Elimina', prefixIcon: 'e-delete', id: 'Elimina',
      },
      {
        text: 'Aggiungi', tooltipText: 'Aggiungi', prefixIcon: 'e-plus', id: 'Aggiungi',
      },
      'ColumnChooser'],
      toolbar: [{
        text: 'Elimina', tooltipText: 'Elimina', prefixIcon: 'e-delete', id: 'Elimina',
      },
      {
        text: 'Aggiungi', tooltipText: 'Aggiungi', prefixIcon: 'e-plus', id: 'Aggiungi',
      },
      'ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Print', 'Search'], // { text: 'Dettagli', tooltipText: 'Dettagli', prefixIcon: 'e-expand', id: 'Dettagli' }
    }
  },
  computed: {
    getUnitaMisura() {
      // console.log(this.$store.getters['dizionari/getUnitaMisura'])
      return this.$store.getters['dizionari/getUnitaUtilizzo']
    },
  },
  /* this.$http.get('/good-table/basic')
      .then(res => { this.rows = res.data }) */
  mounted() {
    // this.loadCatalogs()
  },
  methods: {
    catalogEventHandler() {
      this.$refs['modal-unitautilizzo-event-handler'].setTitle('Aggiungi Unità Utilizzo')
      this.$refs['modal-unitautilizzo-event-handler'].resetUnitaMisura()
      this.$refs['modal-unitautilizzo-event-handler'].openModal()
    },
    async loadCatalogs() {
      await axios.post(process.env.VUE_APP_URL_API_SERVER, [{}],
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Cat-Catalogo',
            metodo: 'getCataloghi',
            ACL: '',
          },
        }).then(res => {
        // console.log('cataloghi', res)
        if (res.data.esito === 'OK') {
          this.$store.dispatch('catalogs/setCatalogs', res.data.cataloghi)
        }
      })
        .catch(e => e)
    },
    onDeselectRow() {
      if (this.$refs.overviewgridUnitaUtilizzo.getSelectedRecords().length === 0) {
        this.$refs.overviewgridUnitaUtilizzo.ej2Instances.toolbarModule.enableItems(['overviewgrid_delete'], false)
      }
    },
    onRowSelected() {
      this.$refs.overviewgridUnitaUtilizzo.ej2Instances.toolbarModule.enableItems(['overviewgrid_delete'], true)
      // console.log(this.selectedRow)
    },
    rowDataBound() {
    },
    async actionTableBegin() {
      // console.log('action start', action)
      /* if (action.requestType === 'save') {
        let prova = { ...action.data }
        prova.id = prova.id === undefined ? 0 : prova.id
        delete prova.Azioni
        console.log('provaaaaa444', prova)
        const dataParsing = JSON.stringify([{ ...prova }])
        // console.log(dataParsing)
        let cataloghi = []
        await axios.post(process.env.VUE_APP_URL_API_SERVER, dataParsing,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: String(JSON.parse(sessionStorage.getItem('activeAgency')).value),
            classe: 'Cat-Catalogo',
            metodo: 'setCatalogo',
            ACL: 'vat',
          },
        }).then(res => {
        console.log('res setCatalogo', res)
        if (res.data.esito === 'OK') {
          cataloghi = [...res.data.cataloghi]
          this.updateListinoToGrid(cataloghi, { ...prova, id: null})
        }
      })
        .catch(e => e)
      } */
    },
    actionTableComplete(args) {
      // console.log('action complete', args)
      if(args.action === 'add') {
      }
      // this.updateListinoToGrid(cataloghi)
    },
    updateunitaMisuraToGrid(unitaMisura, elementToDeleteWithNoID) {
      // console.log('elementToDeleteWithNoID', elementToDeleteWithNoID)
      let typeOperation = ''
      unitaMisura.forEach(element => {
        const index = this.$refs.overviewgridUnitaUtilizzo.getRowIndexByPrimaryKey({ id: element.id})
        // console.log(index)
        if(index >= 0) {
          // console.log('dentro index >0')
          this.$refs.overviewgridUnitaUtilizzo.updateRow(index, element)
          typeOperation = 'Aggiornata/e'
          // this.$refs.overviewgridUnitaUtilizzo.deleteRecord()
        } else {
          typeOperation = 'Aggiunta/e'
          this.$refs.overviewgridUnitaUtilizzo.addRecord(element, 0)
        }
      })
      this.$refs.toastRef.show({
        title: 'Operazione Completata!', content: `'Unita Utilizzo/i ${typeOperation} Correttamente'`, cssClass: 'e-toast-success', icon: 'e-success toast-icons',
      })
      return
    },
    showDetails() {
      // console.log(params)
      this.$refs['modal-unitautilizzo-event-handler'].setTitle('Modifica Unità Utilizzo')
      this.$refs['modal-unitautilizzo-event-handler'].openModal()
    },
    dataBound() {
      // console.log(this.$refs.overviewgridUnitaUtilizzo)
      this.$refs.overviewgridUnitaUtilizzo.autoFitColumns(['Azioni'])
    },
    commandColumm(args) {
      // console.log('commandColumm', args)
      if (args.commandColumn.type === 'Dettagli') {
        this.$refs['modal-unitautilizzo-event-handler'].setTitle('Modifica Unità Utilizzo')
        this.$refs['modal-unitautilizzo-event-handler'].setUnitaUtilizzoModal(args.rowData)
        this.$refs['modal-unitautilizzo-event-handler'].openModal()
      }
      if (args.commandColumn.type === 'Elimina') {
        // console.log([{ id: args.rowData.id }])
        this.alertAndConfirm([{ id: args.rowData.id }])
      }
    },
    contextMenuClick(args) {
      // console.log('ciao sono menu click', args)
      if (args.item.id === 'Dettagli' && 'rowData' in args.rowInfo) {
        this.$refs['modal-unitautilizzo-event-handler'].setUnitaUtilizzoModal(args.rowInfo.rowData)
        this.showDetails(args.rowInfo.rowData.id)
      } else if (args.item.id === 'DeleteCustom') {
        this.alertAndConfirm([{ id: args.rowInfo.rowData.id }])
      }
    },
    deleteRaparts(id) {
      let recordToDelete = []
      if (id === undefined) recordToDelete = this.$refs.overviewgridUnitaUtilizzo.getSelectedRecords().map(el => ({ id: el.id }))
      else recordToDelete = id
      // console.log(recordToDelete, 'wow')
      /* axios.post(process.env.VUE_APP_URL_API_SERVER, JSON.stringify(recordToDelete),
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Cat-Catalogo',
            metodo: 'deleteCataloghi',
            ACL: '',
          },
        }) */
        deleteUnitaUtilizzo(recordToDelete).then(res => {
        // console.log(res)
        // console.log('record', recordToDelete)
        if (res.data.esito === 'OK') {
          console.log('res', res)
          recordToDelete.forEach(record => this.$refs.overviewgridUnitaUtilizzo.deleteRecord('id', record))
          // mixins
          // this.productDeletedCorrectlyAlert()
          this.$refs.toastRef.show({
            title: 'Operazione Completata!', content: 'Unita Utilizzo Eliminata/e Correttamente', cssClass: 'e-toast-success', icon: 'e-success toast-icons',
          })
        } else if(res.data.esito === 'KO') {
          // console.log('dentro ko')
          this.$refs.toastRef.show({
            title: 'Errore Cancellazione', content: res.data.messaggio, cssClass: 'e-toast-danger', icon: 'e-error toast-icons',
          })
        }
      }).catch(e => e)
    },
    alertAndConfirm(id) {
      // console.log('sono su alert confirm id', id)
      this.$swal({
        title: 'Sei Sicuro?',
        text: 'Verranno rimossi permanentemente',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'Annulla',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(res => {
        if (res.value) {
          this.deleteRaparts(id)
        }
      })
    },
    toolbarClick(args) {
      // console.log('toolbarClick', args)
      if (args.item.id === 'Dettagli') {
        this.showDetails(args.rowData.id)
      }
      if (args.item.id === 'Aggiungi') {
        this.$refs['modal-unitautilizzo-event-handler'].setTitle('Aggiungi Unità Utilizzo')
        this.$refs['modal-unitautilizzo-event-handler'].resetUnitaUtilizzo()
        this.$refs['modal-unitautilizzo-event-handler'].openModal()
      }
      if (args.item.id === 'Elimina' && this.$refs.overviewgridUnitaUtilizzo.getSelectedRecords().length > 0) {
        // console.log('delete')
        this.alertAndConfirm()
      }
      if (args.item.id === 'overviewgridUnitaUtilizzo_pdfexport') { // 'Grid_pdfexport' -> Grid component id + _ + toolbar item name
        const exportProperties = {
          //you can customize the name as per your requirement
          fileName: `Unità_Utilizzo_${moment().format('DD-MM-YYYY')}.xlsx`,
        }
        this.$refs.overviewgridUnitaUtilizzo.pdfExport(exportProperties)
        // this.$refs.overviewgridUnitaUtilizzo.pdfExport(exportProperties)
      }
      if (args.item.id === 'overviewgridUnitaUtilizzo_excelexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
        const exportProperties = {
          //you can customize the name as per your requirement
          fileName: `Unità_Utilizzo_${moment().format('DD-MM-YYYY')}.xlsx`,
        }
        this.$refs.overviewgridUnitaUtilizzo.excelExport(exportProperties)
      }
      if (args.item.id === 'overviewgridUnitaUtilizzo_csvexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
        const exportProperties = {
          //you can customize the name as per your requirement
          fileName: `Unità_Utilizzo_${moment().format('DD-MM-YYYY')}.xlsx`,
        }
        this.$refs.overviewgridUnitaUtilizzo.csvExport(exportProperties)
      }
    },
    load() {
      // const gObj = this.$refs.overviewgridUnitaUtilizzo.$el.ej2_instances[0]
      if (!this.isDesktop) {
        this.$refs.overviewgridUnitaUtilizzo.$el.ej2_instances[0].rowRenderingMode = 'Vertical'
      }
      /* const proxy = this
      this.$refs.overviewgridUnitaUtilizzo.$el.ej2_instances[0].on('data-ready', () => {
        proxy.dReady = true
      })
      this.$refs.overviewgridUnitaUtilizzo.$el.addEventListener('DOMSubtreeModified', () => {
        if (proxy.dReady && proxy.stTime && proxy.isDataChanged) {
          const e = performance.now() - proxy.stTime
          proxy.loadTime = `Load Time: <b>${e.toFixed(0)}</b><b>ms</b>`
          proxy.stTime = null
          proxy.dReady = false
          proxy.isDataChanged = false
          // proxy.$refs.msgelement.classList.remove('e-hide')
        }
      }) */
    },
  },
  provide: {
    grid: [Toolbar, PdfExport, ExcelExport, Filter, Selection, Sort, Page, ColumnChooser, ContextMenu, CommandColumn, Resize, Scroll, Aggregate, Edit],
  },
}
</script>
